import React, { useEffect } from 'react';
import { connect, RootStateOrAny, useSelector } from 'react-redux';
import { window } from 'browser-monads';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { loadMainContent } from '../actions/welcome';
import Home from '../components/home';
import Welcome from '../components/welcome';

interface I_AppProps {
  loadMainContent: () => Promise<void>;
}

const App: React.FC<I_AppProps> = (): JSX.Element => {
  const splashLoaded: boolean = useSelector((state: RootStateOrAny) => state.loaded);
  const [loaded, setLoaded] = React.useState<boolean>(
    true,
    // !!splashLoaded
  );

  useEffect(() => {
    if (!loaded) {
      setTimeout(async () => {
        window.localStorage.setItem('portfolio_splash_screen_shown', 'true');
        setLoaded(true);
      }, 4000);
    }
  }, []);

  if (!loaded) {
    return <Welcome />;
  }

  return <Home />;
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    loadMainContent: () => dispatch(loadMainContent()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
