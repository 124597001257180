import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import Fade from "react-reveal/Fade"
import * as aboutStyles from './../../../styles/components/home/about/index.module.css'
import { I_AboutProps } from './interfaces';
import DesignSvg from './svgs/design';
import DeploySvg from './svgs/deploy';
import DevelopSvg from './svgs/develop';

const About: React.FC<I_AboutProps> = ({about}: I_AboutProps): JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state.theme);

  return (
    <div id={"about"} className={`container ${aboutStyles.svg_section}`} ref={about}>
      <div className={`row`}>
        <div className={`col-12 col-md-12 text-center`}>
          <Fade bottom>
            <h2 className={`${aboutStyles.twister} ${aboutStyles[`twister_${currentTheme}`]}`}>
              Here's what i do
            </h2>
          </Fade>
        </div>
        <div className={`col-12 col-md-4 text-center ${aboutStyles.box_svg}`}>
          <Fade bottom>
            <p className={`${aboutStyles.sub_headers} ${aboutStyles[`sub_headers_${currentTheme}`]}`}>
              Design
            </p>
            <DesignSvg />
            <p className={`${aboutStyles.sub_description} ${aboutStyles[`sub_description_${currentTheme}`]}`}>
              From creating wireframes to high quality mockups, I adore taking part in the conception phase of any product. 
            </p>
          </Fade>
        </div>

        <div className={`col-12 col-md-4 text-center ${aboutStyles.circle_svg}`}>
          <Fade bottom>
            <p className={`${aboutStyles.sub_headers} ${aboutStyles[`sub_headers_${currentTheme}`]}`}>
              Develop
            </p>
            <DevelopSvg />
            <p className={`${aboutStyles.sub_description} ${aboutStyles[`sub_description_${currentTheme}`]}`}>
              With a text editor and a vision, I'm a sucker for writing code. Breathing life into designs excites me.
            </p>
          </Fade>
        </div>

        <div className={`col-12 col-md-4 text-center ${aboutStyles.pattern_svg}`}>
          <Fade bottom>
            <p className={`${aboutStyles.sub_headers} ${aboutStyles[`sub_headers_${currentTheme}`]}`}>
              Deploy
            </p>
            <DeploySvg />
            <p className={`${aboutStyles.sub_description} ${aboutStyles[`sub_description_${currentTheme}`]}`}>
              Provisioning resources and the entire complete architecture to the cloud is the awesomeness that I thrive in.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default About;