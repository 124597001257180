import React from 'react';
import * as darkNameStyles from './../../styles/components/welcome/nameSvg-dark.module.css';
import { I_NameSvgProps } from './interfaces';
import { COLORS_SHARED } from '../../theme';

const NameSvg: React.FC<I_NameSvgProps> = ({ useLightTheme }): JSX.Element => {
  const svgRef = React.useRef<any>(null);

  const stylesToUse = darkNameStyles;
  const strokeColor: string = useLightTheme ? COLORS_SHARED.dark : COLORS_SHARED.pink;
  const strokeWidth: number = 1;

  return (
    <svg
      ref={svgRef}
      className={stylesToUse.svg}
      width="168"
      height="64"
      viewBox="0 0 168 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={stylesToUse.path1}
        d="M9.66802 31.039V50H0.757019V17.036H9.40002V21.123C10.338 19.515 11.678 18.2867 13.42 17.438C15.162 16.5893 16.9934 16.165 18.914 16.165C22.8 16.165 25.748 17.3933 27.758 19.85C29.8127 22.262 30.84 25.3887 30.84 29.23V50H21.929V30.771C21.929 28.8057 21.4154 27.22 20.388 26.014C19.4054 24.808 17.8867 24.205 15.832 24.205C13.956 24.205 12.4597 24.8527 11.343 26.148C10.2264 27.4433 9.66802 29.0737 9.66802 31.039Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        className={stylesToUse.path2}
        d="M39.884 52.01V17.036H48.795V52.881C48.795 56.097 47.857 58.71 45.981 60.72C44.105 62.73 41.6036 63.735 38.477 63.735C36.2883 63.735 34.6133 63.4893 33.452 62.998V55.561C34.4346 55.7843 35.3503 55.896 36.199 55.896C38.6556 55.896 39.884 54.6007 39.884 52.01ZM40.42 10.068C39.348 8.99601 38.812 7.70067 38.812 6.182C38.812 4.66334 39.348 3.368 40.42 2.29601C41.492 1.17934 42.7873 0.621002 44.306 0.621002C45.8246 0.621002 47.12 1.157 48.192 2.229C49.264 3.301 49.8 4.61867 49.8 6.182C49.8 7.70067 49.264 8.99601 48.192 10.068C47.12 11.0953 45.8246 11.609 44.306 11.609C42.7873 11.609 41.492 11.0953 40.42 10.068Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        className={stylesToUse.path3}
        d="M67.1791 40.419C68.8318 42.0717 70.8195 42.898 73.1422 42.898C75.4648 42.898 77.4301 42.0717 79.0381 40.419C80.6908 38.7663 81.5172 36.466 81.5172 33.518C81.5172 30.57 80.6908 28.2697 79.0381 26.617C77.4301 24.9643 75.4648 24.138 73.1422 24.138C70.8195 24.138 68.8318 24.9643 67.1791 26.617C65.5711 28.2697 64.7672 30.57 64.7672 33.518C64.7672 36.466 65.5711 38.7663 67.1791 40.419ZM60.7471 20.989C64.0525 17.6837 68.1842 16.031 73.1422 16.031C78.1002 16.031 82.2095 17.6837 85.4701 20.989C88.7755 24.2943 90.4281 28.4707 90.4281 33.518C90.4281 38.5653 88.7755 42.7417 85.4701 46.047C82.2095 49.3523 78.1002 51.005 73.1422 51.005C68.1842 51.005 64.0525 49.3523 60.7471 46.047C57.4865 42.7417 55.8561 38.5653 55.8561 33.518C55.8561 28.4707 57.4865 24.2943 60.7471 20.989Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        className={stylesToUse.path4}
        d="M95.1171 51.809L103.157 49.665C103.47 51.4963 104.318 52.9927 105.703 54.154C107.088 55.3153 108.807 55.896 110.862 55.896C116.713 55.896 119.639 52.8363 119.639 46.717V44.305C118.88 45.511 117.718 46.516 116.155 47.32C114.592 48.124 112.693 48.526 110.46 48.526C106.083 48.526 102.42 47.0073 99.4721 43.97C96.5688 40.9327 95.1171 37.1137 95.1171 32.513C95.1171 28.0463 96.5688 24.2497 99.4721 21.123C102.375 17.9963 106.038 16.433 110.46 16.433C112.872 16.433 114.882 16.8797 116.49 17.773C118.098 18.6217 119.215 19.6713 119.84 20.922V17.036H128.416V46.449C128.416 51.407 126.987 55.5163 124.128 58.777C121.269 62.0823 116.937 63.735 111.13 63.735C106.797 63.735 103.157 62.5737 100.209 60.251C97.3058 57.9283 95.6084 55.1143 95.1171 51.809ZM112.001 40.888C114.279 40.888 116.133 40.1287 117.562 38.61C119.036 37.0913 119.773 35.059 119.773 32.513C119.773 30.0117 119.014 28.0017 117.495 26.483C116.021 24.9643 114.19 24.205 112.001 24.205C109.723 24.205 107.825 24.9643 106.306 26.483C104.832 28.0017 104.095 30.0117 104.095 32.513C104.095 35.059 104.832 37.0913 106.306 38.61C107.78 40.1287 109.678 40.888 112.001 40.888Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        className={stylesToUse.path5}
        d="M158.686 50C158.508 49.1067 158.418 47.923 158.418 46.449C157.57 47.923 156.297 49.0397 154.599 49.799C152.947 50.5137 151.182 50.871 149.306 50.871C145.42 50.871 142.361 49.6427 140.127 47.186C137.894 44.6847 136.777 41.5803 136.777 37.873V17.036H145.688V36.064C145.688 38.0293 146.202 39.6373 147.229 40.888C148.257 42.094 149.753 42.697 151.718 42.697C153.639 42.697 155.158 42.1163 156.274 40.955C157.391 39.749 157.949 38.1633 157.949 36.198V17.036H166.86V44.037C166.86 46.1363 166.972 48.124 167.195 50H158.686Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default NameSvg;
