// extracted by mini-css-extract-plugin
export var container_section = "index-module--container_section--13XRc";
export var title = "index-module--title--1swh1";
export var title_light = "index-module--title_light--2bOJy";
export var title_dark = "index-module--title_dark--2mCVD";
export var mid_row = "index-module--mid_row--NnpYX";
export var description = "index-module--description--1N6vH";
export var description_light = "index-module--description_light--2DSMc";
export var description_dark = "index-module--description_dark--39QpK";
export var image_container = "index-module--image_container--3dCDG";
export var mailto_light = "index-module--mailto_light--2LpxS";
export var mailto_dark = "index-module--mailto_dark--Fcr56";