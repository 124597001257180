// extracted by mini-css-extract-plugin
export var svg_section = "index-module--svg_section--yX5LJ";
export var twister = "index-module--twister--1pWJF";
export var twister_light = "index-module--twister_light--1JfMr";
export var twister_dark = "index-module--twister_dark--tZGhN";
export var sub_headers = "index-module--sub_headers--2P3lE";
export var sub_headers_light = "index-module--sub_headers_light--2F4Xx";
export var sub_headers_dark = "index-module--sub_headers_dark--3ekbI";
export var sub_description = "index-module--sub_description--3Folp";
export var sub_description_light = "index-module--sub_description_light--3smWx";
export var sub_description_dark = "index-module--sub_description_dark--3JDbP";
export var box_svg = "index-module--box_svg--1GMB1";
export var circle_svg = "index-module--circle_svg--1UdkJ";
export var pattern_svg = "index-module--pattern_svg--2guu4";