import React from 'react'
import Fade from "react-reveal/Fade"
import { RootStateOrAny, useSelector } from 'react-redux'
import { I_ContactProps } from './interfaces'
import * as contactStyles from "../../../styles/components/home/contact/index.module.css"
import Image from '../../shared/Image'
import { CONTACT_IMAGE_PROPS } from '../../../config/constants'

const Contact: React.FC<I_ContactProps> = ({contact}: I_ContactProps): JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state?.theme);

  return (
    <div id={"contact"} className={`container ${contactStyles.container_section}`} ref={contact}>

      <div className={`row text-center`}>

        <div className={`col-12 col-md-12 col-sm-12`}>
          <Fade bottom>
            <h4 className={`${contactStyles.title} ${contactStyles[`title_${currentTheme}`]}`}>
              Let's work together
            </h4>
          </Fade>
        </div>

        <div className={`row ${contactStyles.mid_row}`}>
          <div className={`col-12 col-md-7 col-sm-12 text-center`}>
            <Fade bottom>
              <p className={`${contactStyles.description} ${contactStyles[`description_${currentTheme}`]}`}>
                Whether it's an interesting idea for a project that you have, some consultancy work you may need, or just a quick hello, I'd love to chat! I typically respond within a few hours.
              </p>
            </Fade>
            <Fade bottom>
              <a href={`mailto:briancanspit@gmail.com`} className={`${contactStyles[`mailto_${currentTheme}`]} btn btn-outline-secondary btn-md mr-2 hoverable`} target={"_blank"}>
                Shoot me a message
              </a>
            </Fade>
          </div>

          <div className={`col-12 col-md-5 col-sm-12 d-none d-md-block d-lg-block`}>
            <div className={`${contactStyles.image_container}`}>
              <Fade bottom>
                <Image {...CONTACT_IMAGE_PROPS} />
              </Fade>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Contact;