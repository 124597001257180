import React from 'react'
import SEO from '../shared/SEO'
import NameSvg from './nameSvg'
import { RootStateOrAny, useSelector } from 'react-redux';
import * as welcomeStyles from "./../../styles/components/welcome/index.module.css"
import { isLightTheme } from '../../util';

const Welcome: React.FC<{}> = (): JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state.theme);
  const useLightTheme: boolean = isLightTheme(currentTheme);
  const themeStyles = welcomeStyles[currentTheme];

  return (
    <React.Fragment>
      <SEO title={"Welcome · Brian Njogu"} />
      <div className={`${welcomeStyles.container} ${themeStyles}`}>
        <NameSvg useLightTheme={useLightTheme} />
      </div>
    </React.Fragment>
  ) 
}

export default Welcome;