// extracted by mini-css-extract-plugin
export var container = "index-module--container--x6Qqv";
export var wrapper = "index-module--wrapper--3D6uu";
export var left_col_section = "index-module--left_col_section--1MjQv";
export var page_title = "index-module--page_title--1Qc4H";
export var page_title_light = "index-module--page_title_light--1rTu1";
export var page_title_dark = "index-module--page_title_dark--1HfEL";
export var description = "index-module--description--1V91U";
export var description_light = "index-module--description_light--1yXcN";
export var description_dark = "index-module--description_dark--13GEX";
export var accordion = "index-module--accordion--HAnaD";
export var accordion_item = "index-module--accordion_item--1mGPh";
export var accordion_wrap = "index-module--accordion_wrap--WR_aJ";
export var accordion_title = "index-module--accordion_title--7O2df";
export var accordion_title_light = "index-module--accordion_title_light--1k9NK";
export var accordion_title_dark = "index-module--accordion_title_dark--3WxwE";
export var icon = "index-module--icon--Ytht5";
export var accordion_content = "index-module--accordion_content--uJB3R";
export var role_mobile = "index-module--role_mobile--IzyCl";
export var role_mobile_light = "index-module--role_mobile_light--1mvcj";
export var role_mobile_dark = "index-module--role_mobile_dark--2oxGB";
export var period = "index-module--period--2qk-i";
export var period_light = "index-module--period_light--2HN2X";
export var period_dark = "index-module--period_dark--Wvp82";
export var company = "index-module--company--3mRmq";
export var company_light = "index-module--company_light--1gy4i";
export var company_dark = "index-module--company_dark--2R29a";
export var p_light = "index-module--p_light--3MJWY";
export var p_dark = "index-module--p_dark--32f8D";
export var li_light = "index-module--li_light--1lxDP";
export var li_dark = "index-module--li_dark--12KfN";
export var icon_light = "index-module--icon_light--1mmk3";
export var icon_dark = "index-module--icon_dark--3USD5";
export var tools_light = "index-module--tools_light--31rcb";
export var tools_dark = "index-module--tools_dark--3Gi4u";
export var tool_separator_light = "index-module--tool_separator_light--21QVy";
export var tool_separator_dark = "index-module--tool_separator_dark--1yCCH";
export var company_website_light = "index-module--company_website_light--ykxwy";
export var company_website_dark = "index-module--company_website_dark--2GuxT";
export var company_website = "index-module--company_website--1cvd6";
export var connect_light = "index-module--connect_light--30Yqx";
export var connect_dark = "index-module--connect_dark--14uYR";
export var tooling = "index-module--tooling--2I_Po";