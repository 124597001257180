import React, { useCallback, useState } from 'react'
import { useSelector, RootStateOrAny } from 'react-redux';
import Fade from "react-reveal/Fade"
import { I_WorkProps } from './interfaces'
import AddSharpIcon from '@material-ui/icons/AddSharp';
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';
import { getFormattedWorkPeriodLength, getFormattedWorkDatesBetween, sortWorkExperiencesByOrder } from '../../../util';
import * as workStyles from './../../../styles/components/home/work/index.module.css'
import Image from '../../shared/Image';
import { WORK_IMAGE_PROPS } from '../../../config/constants';
import { I_WorkExperience, WORK_EXPERIENCES } from '../../../config/work';

const Work: React.FC<I_WorkProps> = ({work}: I_WorkProps): JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state.theme);

  const [activeAccordion, setActiveAccordion] = useState<null | string>(null);

  const toggleAccordion = useCallback((accordion: string): void => {
    if(activeAccordion === accordion){
      setActiveAccordion(null);
      return;
    }
    setActiveAccordion(accordion);
  }, [activeAccordion])
 
  return (
    <div id={"work"} className={`container ${workStyles.container}`} ref={work}>
      <div className={`row`}>

        <div className={`col-12 col-md-12 col-sm-12 text-center`}>
            <Fade bottom>
              <h3 className={`${workStyles.page_title} ${workStyles[`page_title_${currentTheme}`]}`}>
                Where I've worked
              </h3>
            </Fade>
        </div>

        <div className={`col-12 col-md-4 ${workStyles.left_col_section} d-none d-md-block d-lg-block`}>
          <Fade bottom>
            <Image {...WORK_IMAGE_PROPS} />
          </Fade>
        </div>

        <div className={`col-12 col-md-8`}>

          <div className={`col-12 col-md-12 col-sm-12 text-center ${workStyles.wrapper}`}>
            <p className={`${workStyles.description} ${workStyles[`description_${currentTheme}`]}`}>
              From spearheading projects to collaborating with talented colleagues, most of my experience is largely attributed to these awesome places I've been a part of.
            </p>
          </div>
        
          <div className={`col-12 col-md-12 col-sm-12`}>
            <div className={`${workStyles.accordion}`}>

              {WORK_EXPERIENCES.sort(sortWorkExperiencesByOrder)?.map((experience, key) => {

                const datesBetween = getFormattedWorkDatesBetween(experience);
                const periodSoFar = getFormattedWorkPeriodLength(experience);

                const {Duties, Tools, website}: I_WorkExperience = experience;

                const isAccordionActive: boolean = (activeAccordion === `accordion${key}`);

                return (
                  <div className={`${workStyles.accordion_item}`} key={key}>

                    <button onClick={(): void => toggleAccordion(`accordion${key}`)} id={`accordion_button_${key}`} aria-expanded={isAccordionActive}>

                      <div className={`${workStyles.accordion_wrap}`}>
                        <span className={`${workStyles.accordion_title}`}>
                          <Fade delay={100} bottom>
                            <span className={`${workStyles.period} ${workStyles[`period_${currentTheme}`]}`}>
                              {datesBetween} • ({periodSoFar})
                            </span>
                            <span className={`${workStyles.company} ${workStyles[`company_${currentTheme}`]}`}>
                              {experience?.company}
                            </span>
                          </Fade>
                        </span>

                        <span className={`d-none d-md-block d-lg-block ${workStyles.accordion_title} ${workStyles[`accordion_title_${currentTheme}`]}`}>
                          <Fade delay={100} bottom>
                            {experience?.role}
                          </Fade>
                        </span>

                        <Fade delay={100} bottom>
                          {isAccordionActive ? (
                            <RemoveSharpIcon className={`${workStyles[`icon_${currentTheme}`]}`} />
                          ) : (
                            <AddSharpIcon className={`${workStyles[`icon_${currentTheme}`]}`} />
                          )}
                        </Fade>
                      </div>

                    </button>

                    <div className={`${workStyles.accordion_content}`}>
                      <span className={`d-md-none d-lg-none d-sm-block squiggly_line squiggly_line_${currentTheme} ${workStyles.role_mobile} ${workStyles[`role_mobile_${currentTheme}`]}`}>
                          {experience?.role}
                      </span>

                      {/* <Summary className={`${workStyles[`p_${currentTheme}`]}`} /> */}

                      {Duties && (
                        <Duties className={`${workStyles[`li_${currentTheme}`]}`} />
                      )}

                      {Tools && (
                        <Tools
                          className={`${workStyles[`p_${currentTheme}`]}`}
                          toolClassName={`${workStyles[`tools_${currentTheme}`]} ${workStyles[`tools_${currentTheme}`]}`}
                          toolSeparatorClassName={`${workStyles[`tool_separator_${currentTheme}`]}`}
                        />
                      )}
                      
                      <a className={`${workStyles.company_website} ${workStyles[`company_website_${currentTheme}`]}`} href={website} target={"_blank"}>
                          Visit their website
                      </a>
                    </div>

                  </div>
                )
              })}

            </div>
          </div>

        </div>

        <div className={`col-12 col-md-12 col-sm-12 text-center`}>
          <Fade delay={100} bottom>
            <a href={`https://linkedin.com/in/liltrendi/`} className={`${workStyles[`connect_${currentTheme}`]} btn btn-outline-secondary btn-md mr-2 hoverable`} target={"_blank"}>
              Connect On LinkedIn
            </a >
          </Fade>
        </div>
        
      </div>
    </div>
  )
}

export default Work;