// extracted by mini-css-extract-plugin
export var svg = "deploy-module--svg--2W6Xg";
export var targetInCircleAnimation = "deploy-module--target-in-circle-animation--qMvYY";
export var targetInCircleFadeAnimation = "deploy-module--target-in-circle-fade-animation--39JkV";
export var svg__stroke_circle_light = "deploy-module--svg__stroke_circle_light--31q51";
export var svg__stroke_circle_dark = "deploy-module--svg__stroke_circle_dark--moWUp";
export var svg__fill_circle_light = "deploy-module--svg__fill_circle_light--2vqo5";
export var svg__fill_circle_dark = "deploy-module--svg__fill_circle_dark--wZm17";
export var anim_delay_100 = "deploy-module--anim_delay_100--1Eafq";
export var anim_delay_200 = "deploy-module--anim_delay_200--P-uMp";
export var anim_delay_400 = "deploy-module--anim_delay_400--3HcfK";