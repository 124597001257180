// extracted by mini-css-extract-plugin
export var svg_image = "develop-module--svg_image--o8Otr";
export var svg__stroke_path_light = "develop-module--svg__stroke_path_light--3iczP";
export var svg__stroke_path_dark = "develop-module--svg__stroke_path_dark--275fk";
export var svg__stroke_circle_light = "develop-module--svg__stroke_circle_light--3M4yN";
export var svg__stroke_circle_dark = "develop-module--svg__stroke_circle_dark--2d6fo";
export var svg__fill_circle_light = "develop-module--svg__fill_circle_light--P-alF";
export var svg__fill_circle_dark = "develop-module--svg__fill_circle_dark--wnqOf";
export var svg__stroke_circle_light_mid = "develop-module--svg__stroke_circle_light_mid--20eFa";
export var svg__stroke_circle_dark_mid = "develop-module--svg__stroke_circle_dark_mid--30pYn";
export var dots_pattern__sound_wave = "develop-module--dots_pattern__sound_wave--2xgqF";
export var scaleSoundWave = "develop-module--scale-sound-wave--38GKb";
export var dots_pattern__fade_in_circle = "develop-module--dots_pattern__fade_in_circle--24cGh";
export var fadeInFromSoundWave = "develop-module--fade-in-from-sound-wave--Fv3Po";