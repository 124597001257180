import React from "react"
import { RootStateOrAny, useSelector } from 'react-redux'
import * as deployStyles from "../../../../styles/components/home/about/svgs/deploy.module.css"

const DeploySvg: React.FC<{}> = (): JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state.theme);

  return (
    <svg aria-hidden="true" role="img" focusable="false" className={`${deployStyles[`svg__stroke_circle_${currentTheme}`]} ${deployStyles.svg}`} width="211" height="211" viewBox="0 0 211 211" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className={`${deployStyles[`svg__fill_circle_${currentTheme}`]}`}>
        <circle cx="106.5" cy="104.5" r="23.5" />
      </g>
      <circle className={`${deployStyles.anim_delay_100}`} cx="106.5" cy="104.5" r="49.5" strokeWidth="2" />
      <circle className={`${deployStyles.anim_delay_200}`} cx="106" cy="105" r="77" strokeWidth="2" />
      <circle className={`${deployStyles.anim_delay_400}`} cx="106" cy="104" r="103" strokeWidth="2" />
    </svg>
  )
}

export default DeploySvg