import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { COLORS_SHARED } from '../../../../theme';
import { isLightTheme } from '../../../../util';
import * as designStyles from "../../../../styles/components/home/about/svgs/design.module.css"

const DesignSvg: React.FC<{}> = (): JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state.theme);
  const color: string = isLightTheme(currentTheme) ? COLORS_SHARED.dark : COLORS_SHARED.light;

  return (
    <svg aria-hidden="true" role="img" focusable="false" className={`${designStyles.svg_image}`} width="211" height="211" viewBox="0 0 211 211" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="13" y="13" width="185" height="185" rx="92.5" stroke={color} strokeWidth="2"></rect>
      <path className={`${designStyles.dot_in_box__box}`} stroke={color} strokeWidth="2" d="M13 13h185v185H13z"></path>
      <g className={``}>
        <circle className={`${designStyles.dot_in_box__dot}`} cx="35.5" cy="105.5" r="22.5" fill={color} strokeWidth="2"></circle>
      </g>
    </svg>
  )
}

export default DesignSvg;