import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import FadeIn from "react-fade-in"
import Image from '../../shared/Image'
import { I_IntroProps } from './interfaces'
import * as introStyles from "../../../styles/components/home/intro/index.module.css"
import { HOME_IMAGE_PROPS } from '../../../config/constants'
import { getTotalYearsOfExperience } from '../../../util'
 
const Intro: React.FC<I_IntroProps> = ({allRefs}):JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state.theme);
  
  const resumeLink: string = "https://drive.google.com/file/d/12xR529LP_Glo2vUyHSHAAnsb5TADORKh/view?usp=sharing";
  const portfolioLink: string = "https://drive.google.com/file/d/1Tx1zuJ0WcXLRqTFbz3-b86FxjBIW-ydJ/view?usp=share_link"

  const totalYearsOfExperience = getTotalYearsOfExperience();
 
  return (
    <div id={"intro"} className={`container ${introStyles.container}`} ref={allRefs?.intro}>
      <div className={"row"}>
        <div className={`col-12 col-md-8 col-sm-12`}>
          
          <FadeIn delay={200} className={`${introStyles.about_section} text-center`}>
            <h1 className={`${introStyles.salutation} ${introStyles[`salutation_${currentTheme}`]}`}>
              Hi there, meet
            </h1>
            <span className={`${introStyles.name}`}>
              Brian Njogu
            </span>

            <p className={`${introStyles.job_title} ${introStyles[`job_title_${currentTheme}`]}`}>
              Developer • Techie • Consultant
            </p>
            <p className={`${introStyles.description} ${introStyles[`description_${currentTheme}`]}`}>
              I build solid and scalable software with great user experiences. About {totalYearsOfExperience} of commercial experience helping entities across the world build outstanding products.
            </p>

            <div className={`${introStyles.cta_links_container}`}>
              <div className={`btn-toolbar ${introStyles.about_btns}`}>
                <a target={"_blank"} href={resumeLink} className={`${introStyles[`explore_${currentTheme}`]} btn btn-outline-secondary btn-md hoverable`}>
                  View Resume
                </a >
              </div>

              <div className={`btn-toolbar ${introStyles.about_btns}`}>
                <a target={"_blank"} href={portfolioLink} className={`${introStyles[`portfolio_link_${currentTheme}`]} btn btn-outline-secondary btn-md hoverable`}>
                  My Projects
                </a >
              </div>
            </div>
          </FadeIn>

        </div>

        <div className={`col-12 col-md-4 col-sm-12 text-center ${introStyles.right_row} d-none d-md-block`}>
          <FadeIn delay={200}>
            <Image {...HOME_IMAGE_PROPS} />
          </FadeIn>
        </div>

      </div>
    </div>
  )
}

export default Intro;
