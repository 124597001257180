import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { COLORS_SHARED } from '../../../../theme';
import { isLightTheme } from '../../../../util';
import * as developStyles from "../../../../styles/components/home/about/svgs/develop.module.css"

const DevelopSvg: React.FC<{}> = (): JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state.theme);
  const color: string = isLightTheme(currentTheme) ? COLORS_SHARED.dark : COLORS_SHARED.light;

  return (
    <svg aria-hidden="true" role="img" focusable="false" className={`${developStyles.svg_image} ${developStyles[`svg__stroke_path_${currentTheme}`]} ${developStyles[`svg__stroke_circle_${currentTheme}`]}`} width="211" height="211" viewBox="0 0 211 211" fill="none" xmlns="http://www.w3.org/2000/svg">

      <circle cx="105.5" cy="36.5" r="22.5" strokeWidth="2" /> 
      <circle cx="105.5" cy="171.5" r="22.5" strokeWidth="2" />
      <circle cx="38.5" cy="103.5" r="22.5" strokeWidth="2" />
      <circle cx="172.5" cy="104.5" r="22.5" strokeWidth="2" />

      <g className={`${developStyles[`svg__fill_circle_${currentTheme}`]}`}>
        <circle className={`${developStyles.dots_pattern__sound_wave}`} cx="105.5" cy="104.5" r="23.5" fill={color} />
        <circle cx="105.5" cy="104.5" r="23.5" className={`${developStyles[`svg__stroke_circle_${currentTheme}_mid`]}`} />
      </g>

      <circle cx="38.5" cy="36.5" r="22.5" strokeWidth="2" />
      <circle cx="172.5" cy="36.5" r="22.5" strokeWidth="2" />
      <circle cx="172.5" cy="171.5" r="22.5" strokeWidth="2" />
      <circle cx="38.5" cy="171.5" r="22.5" strokeWidth="2" />

      <g className={`${developStyles[`svg__fill_circle_${currentTheme}`]} ${developStyles.dots_pattern__fade_in_circle}`}>
        <circle cx="38.5" cy="36.5" r="22.5" strokeWidth="2" />
        <circle cx="172.5" cy="36.5" r="22.5" strokeWidth="2" />
        <circle cx="172.5" cy="171.5" r="22.5" strokeWidth="2" />
        <circle cx="38.5" cy="171.5" r="22.5" strokeWidth="2" />
      </g>
    </svg>
  )
}

export default DevelopSvg;