import React from 'react'
import Layout from '../shared/layout';
import SEO from '../shared/SEO';
import Intro from './intro';
import About from './about';
import Work from './work';
import Contact from './contact';
 
const Home: React.FC<{}> = (): JSX.Element => {
  return (
    <Layout>
      <SEO title={"Home · Brian Njogu"} />
      <Intro />
      <About />
      <Work />
      <Contact />
    </Layout>
  ) 
}

export default Home;