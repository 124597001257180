// extracted by mini-css-extract-plugin
export var container = "index-module--container--3ois2";
export var right_row = "index-module--right_row--2GZAK";
export var about_section = "index-module--about_section--2xBLT";
export var salutation = "index-module--salutation--1a7_H";
export var name = "index-module--name--2WMKY";
export var fill = "index-module--fill--3uxgz";
export var job_title = "index-module--job_title--3qTPW";
export var description = "index-module--description--3LhhD";
export var salutation_light = "index-module--salutation_light--nQI7I";
export var salutation_dark = "index-module--salutation_dark--3e6Uc";
export var job_title_light = "index-module--job_title_light--Gr_6p";
export var job_title_dark = "index-module--job_title_dark--IHVes";
export var description_light = "index-module--description_light--314qa";
export var description_dark = "index-module--description_dark--oH6iE";
export var about_btns = "index-module--about_btns--20ZTY";
export var explore_light = "index-module--explore_light--190k9";
export var explore_dark = "index-module--explore_dark--g5W_i";
export var portfolio_link_light = "index-module--portfolio_link_light--mo3-D";
export var portfolio_link_dark = "index-module--portfolio_link_dark--3-xIM";
export var cta_links_container = "index-module--cta_links_container--3prqT";